@if(notFound){
<app-not-found></app-not-found>
}
@else if(!aluno){
<app-loading-screen></app-loading-screen>
}

<div class="card p-5 flex flex-col gap-5" [ngClass]="{'hidden': !aluno}">

    @if(aluno){
    <div>
        <app-profile-picture [user]="aluno" [size]="'big'">
            @if(classroom){
            <div class="flex flex-row gap-3 mt-2">
                <p class="text-light">
                    {{classroom.name}}
                </p>
                @if(classroom.active){
                <chip color="green">Ativo</chip>
                }
                @else {
                <chip color="red">Inativo</chip>
                }
            </div>
            }
        </app-profile-picture>
    </div>
    }

    <mat-tab-group>

        <mat-tab label="Dados Pessoais">
            <app-pessoa-form #alunoForm></app-pessoa-form>
        </mat-tab>

        @if(!aluno || aluno.responsavelId){
        <mat-tab label="Responsável">
            <app-pessoa-form #respForm></app-pessoa-form>
        </mat-tab>
        }

        <mat-tab [label]="'Ocorrências (' + ocorrencias.length + ')'">
            @for(ocorrencia of ocorrencias; track ocorrencia) {
            <div class="flex flex-col gap-3 py-6">

                <div class="flex flex-row gap-3">
                    <app-profile-picture [user]="{id:ocorrencia.created_by}">
                    </app-profile-picture>
                    <div class="flex flex-col">
                        <div class="flex flex-row gap-2 items-center">
                            <span class="font-medium">{{ocorrencia.creator_name}}</span>
                            <span class="text-light text-sm" [matTooltip]="formatDateTime(ocorrencia.created_at)"
                                matTooltipPosition="above">
                                {{calcularTempoPassado(ocorrencia.created_at)}}
                            </span>
                        </div>
                        <div class="flex flex-row gap-2 text-sm">
                            <span class="font-medium">{{ocorrencia.classroom_name}}</span>
                            <span class="text-light">
                                {{ocorrencia.school_name}}
                            </span>
                        </div>
                    </div>
                </div>
                <p class="text-light">
                    {{ocorrencia.description}}
                </p>
            </div>
            }
            @empty{
            <p class="py-3">
                Não há ocorrências
            </p>
            }
        </mat-tab>

        <mat-tab label="Material Didático">
            @if(alunoId){
            <app-student-material-list [studentId]="alunoId"></app-student-material-list>
            }
        </mat-tab>

    </mat-tab-group>

</div>