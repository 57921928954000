import { Component, input, OnInit } from '@angular/core';
import {
  Chart,
  registerables,
  ChartType,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-pie-chart',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './pie-chart.component.html',
  styleUrl: './pie-chart.component.scss',
})
export class PieChartComponent implements OnInit {
  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.barChartData = {
      labels: this.labels(),
      datasets: [
        {
          label: 'Alunos',
          data: this.data(),
        },
      ],
    };

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
        },
      },
    };
  }

  data = input<number[]>([120, 242]);

  labels = input<string[]>(['Presencial', 'Online']);

  barChartType: ChartType = 'doughnut';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData?: ChartData;
  barChartOptions: ChartOptions = {};
}
