@if(paginatedService.notFound){
<app-not-found></app-not-found>
}
@else if(paginatedService.dataSource.data.length == 0){
<app-loading-screen></app-loading-screen>
}

<div [ngClass]="{'hidden': paginatedService.dataSource.data.length == 0}" class="flex flex-col gap-4">

    @if(statistics){
    <div class="flex flex-col md:flex-row gap-4">
        <!-- Alunos Ativos -->
        <div class="flex flex-col gap-4 card basis-full p-4 relative justify-between">
            <h6>Alunos Ativos</h6>

            <div class="flex flex-row items-center text-green-500">
                <h5>{{statistics.active_students}}</h5>
                <mat-icon class="mat-18">bar_chart</mat-icon>
            </div>

            <chip color="gray">
                {{statistics.current_month}}
                {{statistics.current_year}}
            </chip>

            <img class="absolute bottom-0 right-5 h-5/6" src="/assets/people/girl-holding-laptop.png"
                alt="Girl Holding Laptop">
        </div>

        <!-- Alunos Inativos -->
        <div class="flex flex-col gap-4 card basis-full p-4 relative justify-between">
            <h6>Matrículas</h6>

            <div class="flex flex-row items-center text-green-500">
                <mat-icon class="mat-18">add</mat-icon>
                <h5>{{statistics.new_students}}</h5>
            </div>

            <chip color="gray">
                {{statistics.current_month}}
                {{statistics.current_year}}
            </chip>

            <img class="absolute bottom-0 right-5 h-5/6" src="/assets/people/man-holding-cup.png"
                alt="Mand Holding Coffee Cup">
        </div>

        <!-- Alunos por estágio -->
        <div class="flex flex-col gap-3 card basis-full p-4">
            <h6>Estágios</h6>
            <app-bar-chart [displayX]="false" [displayY]="false" [displayLegend]="false"
                [data]="statistics.students_by_stage.data" lenged="Alunos Ativos"
                [labels]="statistics.students_by_stage.labels"></app-bar-chart>
        </div>

        <!-- Alunos Presenciais x Online -->
        <div class="flex flex-col gap-3 card basis-full p-4">
            <h6>Formatos</h6>
            <app-pie-chart [data]="statistics.students_by_format.data" class="max-h-36"
                [labels]="statistics.students_by_format.labels"></app-pie-chart>
        </div>
    </div>
    }

    <div class="card relative">

        <div class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
            [ngClass]="{'hidden': !paginatedService.isLoading, 'flex': paginatedService.isLoading}">
            <app-loading [shape]="'circle'"></app-loading>
        </div>

        <table mat-table [dataSource]="paginatedService.dataSource" class="table !shadow-none">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <span>Aluno</span>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-3">
                    <app-profile-picture [user]="student" [shortenName]="mobileQueryService.matches">
                        <span class="text-light text-nowrap md:hidden">{{student.classroom_name}}</span>
                    </app-profile-picture>
                </td>
            </ng-container>

            <ng-container matColumnDef="school">
                <th mat-header-cell *matHeaderCellDef>
                    <app-table-filter [paginatedService]="paginatedService" [paginatedComponent]="this"
                        (filter)="getStatistics()" [multiple]="false"
                        [options]="schoolUnits" label="Unidade" name="school"></app-table-filter>
                </th>
                <td mat-cell *matCellDef="let student">
                    <span>{{student.school_name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="classroom">
                <th mat-header-cell *matHeaderCellDef>
                    <app-table-filter [paginatedService]="paginatedService"
                    [paginatedComponent]="this" label="Turma" name="classroom"></app-table-filter>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">
                    <p>
                        <span>{{student.classroom_name}}</span>
                    </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    <app-table-filter [paginatedService]="paginatedService"
                        [paginatedComponent]="this" [multiple]="false"
                        [options]="studentStatuses" label="Status" name="status"></app-table-filter>
                </th>
                <td mat-cell *matCellDef="let student" class="!py-4">

                    @if(student.active) {
                    <chip color="green">Ativo</chip>
                    }
                    @else {
                    <chip color="red">Inativo</chip>
                    }
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="mobileQueryService.matches ? mobileColumns : desktopColumns"></tr>
            <tr mat-row *matRowDef="let student; columns: mobileQueryService.matches ? mobileColumns : desktopColumns;"
                [routerLink]="['/aluno', student.id]">
            </tr>
        </table>

        <mat-paginator [showFirstLastButtons]="!mobileQueryService.matches" [hidePageSize]="true">
        </mat-paginator>
    </div>

</div>