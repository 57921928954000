import { Component, input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [CommonModule, BaseChartDirective],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
})
export class BarChartComponent implements OnInit {
  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.barChartData = {
      labels: this.labels(),
      datasets: [
        {
          label: this.lenged(),
          data: this.data() || [],
          backgroundColor: '#9155fd',
          borderWidth: 0,
          borderRadius: 10,
        },
      ],
    };

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false, // Ensures the chart adjusts to the container's aspect ratio
      scales: {
        x: {
          display: this.displayX(),
          beginAtZero: true,
        },
        y: {
          display: this.displayY(),
          suggestedMax: this.max(),
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
      },
    };
  }

  max = input<number>();
  data = input<number[]>();

  displayX = input<boolean>(true);
  displayY = input<boolean>(true);
  displayLegend = input<boolean>(true);

  lenged = input<string>();

  labels = input<string[]>();

  barChartType: any = 'bar';
  barChartPlugins = [];

  barChartData?: ChartData;
  barChartOptions: ChartOptions = {};
}
