import { Component, inject, model } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { IAluno } from '../../models/aluno.model';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ocorrencia-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogTitle,
    MatDialogClose,
    MatInputModule,
    MatFormFieldModule,
    ProfilePictureComponent,
    FormsModule,
  ],
  templateUrl: './ocorrencia-dialog.component.html',
  styleUrl: './ocorrencia-dialog.component.scss',
})
export class OcorrenciaDialogComponent {
  protected apiService = inject(ApiService);
  protected snackBar = inject(MatSnackBar);

  description = model<string>();

  readonly dialogRef = inject(MatDialogRef<OcorrenciaDialogComponent>);
  readonly data = inject<{ student: IAluno; classroomId: string }>(
    MAT_DIALOG_DATA
  );

  onSave() {
    const aluno_id = this.data.student.id;
    const classroom_id = this.data.classroomId;
    const description = this.description();

    this.apiService
      .post('students/occurrences', {
        aluno_id,
        classroom_id,
        description,
      })
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.snackBar.open('Ocorência salva com sucesso!');
        },
      });
  }
}
