import { Component, inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { IAluno } from '../../models/aluno.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { StudentRemovalDialogComponent } from '../student-removal-dialog/student-removal-dialog.component';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { RouterModule } from '@angular/router';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { OcorrenciaDialogComponent } from '../ocorrencia-dialog/ocorrencia-dialog.component';

@Component({
  selector: 'app-student-bottom-sheet',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    RouterModule,
    ProfilePictureComponent,
  ],
  templateUrl: './student-bottom-sheet.component.html',
  styleUrl: './student-bottom-sheet.component.scss',
})
export class StudentBottomSheetComponent {
  protected _bottomSheetRef =
    inject<MatBottomSheetRef<StudentBottomSheetComponent>>(MatBottomSheetRef);

  protected authService = inject(AuthService);

  readonly data = inject<{ student: IAluno; classroomId: string }>(
    MAT_BOTTOM_SHEET_DATA
  );

  readonly dialog = inject(MatDialog);

  canRemove(): boolean {
    return this.authService.hasRole([
      this.authService.roles.Adm,
      this.authService.roles.Coordenador,
    ]);
  }

  onRemoval() {
    if (!this.canRemove()) return;

    const removalSubscription: Observable<string | undefined> = this.dialog
      .open(StudentRemovalDialogComponent, {
        disableClose: true,
        data: {
          student: this.data.student,
          classroomId: this.data.classroomId,
        },
      })
      .afterClosed();

    this._bottomSheetRef.dismiss(removalSubscription);
  }

  openOcorrenciaModal() {
    this._bottomSheetRef.dismiss();
    this.dialog.open(OcorrenciaDialogComponent, {
      width: '500px',
      data: {
        student: this.data.student,
        classroomId: this.data.classroomId,
      },
    });
  }
}
