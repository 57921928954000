@if(paginatedService.notFound){
<app-not-found></app-not-found>
}
@else if(paginatedService.dataSource.data.length == 0){
<app-loading-screen></app-loading-screen>
}

<ng-template #percentage let-number="number">
    <span class="font-medium" [ngClass]="number > 50? 'text-green': 'text-red' ">{{number}}%</span>
</ng-template>

<div class="card relative mt-4" [ngClass]="{'hidden': paginatedService.dataSource.data.length == 0}">

    <div class="h-full w-full justify-center items-center absolute top-0 left-0 z-10 bg-black bg-opacity-5 rounded-default"
        [ngClass]="{'hidden': !paginatedService.isLoading, 'flex': paginatedService.isLoading}">
        <app-loading [shape]="'circle'"></app-loading>
    </div>

    <table mat-table [dataSource]="paginatedService.dataSource" class="table !shadow-none">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                <span>Aluno</span>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-3">
                <app-profile-picture [user]="student" [shortenName]="mobileQueryService.matches">
                    <span class="text-light text-nowrap md:hidden">{{student.classroom_name}}</span>
                </app-profile-picture>
            </td>
        </ng-container>

        <ng-container matColumnDef="school">
            <th mat-header-cell *matHeaderCellDef>

                <app-table-filter [paginatedService]="paginatedService"
                [paginatedComponent]="this" [multiple]="false"
                [options]="schoolUnits" label="Unidade" name="school"></app-table-filter>

            </th>
            <td mat-cell *matCellDef="let student" class="!py-4">
                <span>{{student.school_name}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="classroom">
            <th mat-header-cell *matHeaderCellDef>
                <app-table-filter [paginatedService]="paginatedService"
                [paginatedComponent]="this" label="Turma" name="classroom"></app-table-filter>
            </th>
            <td mat-cell *matCellDef="let student" class="!py-4">
                <p>
                    <span>{{student.classroom_name}}</span>
                </p>
            </td>
        </ng-container>

        <ng-container matColumnDef="attendance">
            <th mat-header-cell *matHeaderCellDef class="!text-center">

                <app-table-filter [paginatedService]="paginatedService"
                [paginatedComponent]="this" [multiple]="false"
                [options]="staticFilters" label="Presença" name="attendance"></app-table-filter>

            </th>
            <td mat-cell *matCellDef="let student" class="!text-center">
                <ng-container
                    *ngTemplateOutlet="percentage; context:{number: student.average_attendance}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="performance">
            <th mat-header-cell *matHeaderCellDef class="!text-center">

                <app-table-filter [paginatedService]="paginatedService"
                [paginatedComponent]="this" [multiple]="false"
                [options]="staticFilters" label="Engajamento" name="performance"></app-table-filter>

            </th>
            <td mat-cell *matCellDef="let student" class="!text-center">
                <ng-container
                    *ngTemplateOutlet="percentage; context:{number: student.average_performance}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="risk">
            <th mat-header-cell *matHeaderCellDef class="!text-center">
                <!-- <span class="md:hidden">Risco</span>
                <span class="max-md:hidden">Risco</span> -->

                <app-table-filter [paginatedService]="paginatedService"
                [paginatedComponent]="this" [multiple]="false"
                [options]="riskClassifications" label="Risco" name="risk"></app-table-filter>

            </th>
            <td mat-cell *matCellDef="let student" class="!text-center">
                @switch (student.risk_classification) {
                @case(ERiskClassification.ALTO){
                <chip color="red">Alto</chip>
                }
                @case(ERiskClassification.MEDIO){
                <chip color="orange">Medio</chip>

                }
                @case(ERiskClassification.BAIXO){
                <chip color="green">Baixo</chip>
                }
                }
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mobileQueryService.matches ? mobileColumns : desktopColumns"></tr>
        <tr mat-row *matRowDef="let student; columns: mobileQueryService.matches ? mobileColumns : desktopColumns;">
        </tr>
    </table>

    <mat-paginator [showFirstLastButtons]="!mobileQueryService.matches" [hidePageSize]="true">
    </mat-paginator>

</div>