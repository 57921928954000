import { Injectable } from '@angular/core';
import { BasePaginatedService } from '../../abstracts/base-paginated.service';
import { Observable } from 'rxjs';

export type StudentStatistics = {
  active_students: number;
  inactive_students: number;
  students_by_format: {
    labels: string[];
    data: number[];
  };
  students_by_stage: {
    labels: string[];
    data: number[];
  };
  current_month: string;
  current_year: string;
  new_students: number;
};

@Injectable({
  providedIn: 'root',
})
export class AlunosService extends BasePaginatedService<any> {
  override resourceUrl: string = 'students/';

  getStatistics(filters: unknown): Observable<StudentStatistics> {
    return this.apiService.getByFilters<StudentStatistics>('students/statistics', filters);
  }
}
