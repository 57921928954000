<button [matMenuTriggerFor]="tableFilter" class="flex flex-row items-center">
    @if(formControl.value?.length){
    <mat-icon>filter_alt</mat-icon>
    }
    @else{
    <mat-icon class="text-light">filter_list</mat-icon>
    }
    {{label()}}
</button>

<mat-menu #tableFilter="matMenu" (close)="onFilter()">

    <div class="px-2">
        <mat-form-field (click)="$event.stopPropagation()" class="no-padding" appearance="outline">
            <mat-label>{{label()}}</mat-label>

            @if(options()){   
                <mat-select [formControl]="formControl" [multiple]="multiple()" (closed)="tableFilter.close.emit()">
                    @for (option of options(); track option) {
                        <mat-option [value]="option.value">{{option.label}}</mat-option>
                    }
                </mat-select>

                
            }
            @else {
                <input matInput [formControl]="formControl" (keyup.enter)="tableFilter._resetAnimation(); tableFilter.close.emit()">
            }

            @if(options() && !multiple() && formControl.value?.length){
            <button matSuffix mat-icon-button aria-label="Clear" (click)="formControl.setValue([]); $event.stopPropagation()">
                <mat-icon>close</mat-icon>
            </button>
            }

        </mat-form-field>
    </div>

</mat-menu>