import { Component, OnInit, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-pessoa-form',
  standalone: true,
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    NgxMaskDirective,
  ],
  templateUrl: './pessoa-form.component.html',
  styleUrl: './pessoa-form.component.scss',
})
export class PessoaFormComponent {
  personalInfoForm: FormGroup;

  protected onBirthdayChange = output();

  protected readonly todayDate = new Date();
  protected readonly startDate = new Date(1995, 0, 1);
  protected readonly appearance: 'fill' | 'outline' = 'outline';

  isReadonly = false;

  constructor(private fb: FormBuilder) {
    this.personalInfoForm = this.fb.group({
      name: ['', [Validators.required]],
      cpf: [
        '',
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(14),
        ],
      ],
      birthdate: ['', [Validators.required]],
      rg: ['', [Validators.required]],
      filiacao1: ['', [Validators.required]],
      filiacao2: ['', [Validators.required]],
      profissao: ['', [Validators.required]],
      local_trabalho_escola: ['', [Validators.required]],
      escolaridade: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      estado_civil: ['', [Validators.required]],
      cidade_natal: ['', [Validators.required]],
      estado_natal: ['', [Validators.required]],
      pais_natal: ['', [Validators.required]],
      contatoEndereco: this.fb.group({
        telefone1: ['', [Validators.required]],
        telefone2: [''],
        email: ['', [Validators.required, Validators.email]],
        rua: ['', [Validators.required]],
        numero: ['', [Validators.required]],
        cep: ['', [Validators.required]],
        bairro: ['', [Validators.required]],
        cidade: ['', [Validators.required]],
        estado: ['', [Validators.required]],
        pais: ['', [Validators.required]],
      }),
    });

    // this.personalInfoForm.disable();
  }
}
