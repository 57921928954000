import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { MobileQueryService } from '../../services/mobile-query.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FaltasConsecutivasDialogComponent } from '../faltas-consecutivas-dialog/faltas-consecutivas-dialog.component';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { NgClass } from '@angular/common';
import { NotFoundComponent } from '../not-found/not-found.component';
import { ChipComponent } from '../chip/chip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OcorrenciaDialogComponent } from '../ocorrencia-dialog/ocorrencia-dialog.component';
import { IAluno } from '../../models/aluno.model';

export type faltasConsecutivas = {
  id: string;
  name: string;
  classroom: string;
  registros_de_falta: {
    date: string;
    present: boolean;
  }[];
};

@Component({
  selector: 'app-faltas-consecutivas',
  standalone: true,
  imports: [
    MatTableModule,
    ProfilePictureComponent,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    LoadingScreenComponent,
    NgClass,
    NotFoundComponent,
    ChipComponent,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  templateUrl: './faltas-consecutivas.component.html',
  styleUrl: './faltas-consecutivas.component.scss',
})
export class FaltasConsecutivasComponent implements OnInit, OnDestroy {
  protected ApiService = inject(ApiService);
  protected faltasConsecutivas = new MatTableDataSource<faltasConsecutivas>([]);
  protected mobileQueryService = inject(MobileQueryService);
  readonly dialog = inject(MatDialog);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  qtd_faltas = 2;

  notFound = false;

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(this.getDateDaysAgo(7)),
    end: new FormControl<Date | null>(new Date()),
  });

  mobileColumns = ['name', 'faltas'];
  desktopColumns = [
    'name',
    'school',
    'classroom',
    'phone',
    'faltas',
    'tratativa',
  ];

  pickerSubscription?: Subscription;

  ngOnInit(): void {
    this.onFilter();

    this.pickerSubscription = this.range.valueChanges.subscribe(() => {
      this.onFilter();
    });
  }
  ngOnDestroy(): void {
    this.pickerSubscription?.unsubscribe();
  }

  onFilter() {
    if (!(this.qtd_faltas > 0)) return;

    this.ApiService.getByFilters<faltasConsecutivas[]>(
      'attendance/faltas-consecutivas',
      {
        qtd_faltas: this.qtd_faltas,
        start_date: this.formatDate(this.range.get('start')?.value),
        end_date: this.formatDate(this.range.get('end')?.value),
      }
    ).subscribe((faltasConsecutivas) => {
      this.faltasConsecutivas.paginator = this.paginator;
      this.faltasConsecutivas.paginator.pageSize = 8;
      this.faltasConsecutivas.data = faltasConsecutivas;
      this.notFound = faltasConsecutivas.length === 0;
    });
  }

  formatDate(date: Date | null | undefined): string {
    if (!date) date = new Date();

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getDateDaysAgo(days: number): Date {
    const today = new Date();
    today.setDate(today.getDate() - days);
    return today;
  }

  verDetalhes(item: faltasConsecutivas) {
    this.dialog.open(FaltasConsecutivasDialogComponent, {
      data: item,
    });
  }

  openWhatsapp(phone: string) {
    if (!phone) {
      console.error('Phone number is required.');
      return;
    }

    // Sanitize phone number (remove any non-digit characters)
    const sanitizedPhone = phone.replace(/\D/g, '');

    // Ensure the phone number has a valid format (e.g., with country code)
    if (sanitizedPhone.length < 10) {
      console.error('Invalid phone number format.');
      return;
    }

    // WhatsApp URL scheme
    const whatsappUrl = `https://wa.me/${sanitizedPhone}`;

    // Open WhatsApp link in a new tab/window
    window.open(whatsappUrl, '_blank');
  }

  handleAbsence(student: IAluno, checked: boolean, classroomId: string) {
    this.ApiService.post('/attendance/absence-handling', {
      student_id: student.id,
      checked,
      start_date: this.formatDate(this.range.get('start')?.value),
      end_date: this.formatDate(this.range.get('end')?.value),
    }).subscribe({
      next: () => {
        student.tratativa = checked;
      },
    });
  }

  openOcorrenciaModal(student: IAluno, classroomId: string) {
    this.dialog
      .open(OcorrenciaDialogComponent, {
        width: '500px',
        data: {
          student,
          classroomId,
        },
      })
      .afterClosed()
      .subscribe((newOccurence) => {
        if (newOccurence) this.handleAbsence(student, true, classroomId);
        else student.tratativa = false;
      });
  }
}
